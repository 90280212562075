@tailwind base;
@tailwind components;
@tailwind utilities;

/* Invisible track, only thumb visible */
* {
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent; /* Visible thumb, invisible track */
}

::-webkit-scrollbar-track {
  background: transparent; /* Completely hides the track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Subtle thumb */
}
